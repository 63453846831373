/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
'use strict';

/**
 * Push attentive mobile data
 */

var attentiveBase = require('../common/attentiveBase.js');

module.exports = {
  init: function () {
    $('body').on('product:afterAddToCart', function (e, cartData) {
      var cartEventEnabled = window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_CART_EVENT_ENABLED;

      if (!cartEventEnabled) {
        console.log('Add to Cart View Event Disabled');
        return;
      }

      var lastAddedItem = cartData ? cartData.lastAddedItem : {};

      if (lastAddedItem.pid && lastAddedItem.quantity) {
        var attentiveActionURL = window.Attentive_Urls ? window.Attentive_Urls.attentiveMobileGetViewData : '';

        if (attentiveActionURL) {
          var getDataResults = attentiveBase.getViewData(attentiveActionURL, {
            productId: lastAddedItem.pid,
            quantity: lastAddedItem.quantity,
            viewType: 'CART'
          });

          attentiveBase.tagCartView(getDataResults);
        }
      }
    });
  }
};
