/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
'use strict';

var attentiveBase = require('../common/attentiveBase.js');

/**
 * Push attentive mobile data
 */

function sendProductData(attentiveActionURL, productId) {
  if (attentiveActionURL) {
    $.ajax({
      dataType: 'json',
      type: 'POST',
      global: false,
      url: attentiveActionURL,
      data: {
        event: 'productView',
        productId: productId
      }
    });
  }
}

function setProductData(attentiveActionURL, productId, event) {
  if (attentiveActionURL) {
    $.ajax({
      dataType: 'json',
      type: 'POST',
      global: false,
      url: attentiveActionURL,
      data: {
        event: event,
        productId: productId
      }
    });
  }
}

module.exports = {
  init: function () {
    // Uncomment the code to enable the ajax based product view tracking. Code commented in AMBLD-136
  /*  $(window).on('load', function () {
      var element = document.querySelector('[data-attentive-mobile]');

      if (element) {
        var attentiveActionURL = window.Attentive_Urls ? window.Attentive_Urls.attentiveMobileTrack : '',
          productId = element.getAttribute('data-pid');

        sendProductData(attentiveActionURL, productId);
      }
    });

    $(document).on('click', '[data-attr="color"] button', function (e) {
      e.preventDefault();

      sendProductData($(this).attr('data-attentiveURL'));
    });

    $(document).on('change', 'select[class*="select-"]', function (e) {
      e.preventDefault();

      var targetOptions = e.currentTarget.options;

      if (targetOptions) {
        var selectedOption = targetOptions[targetOptions.selectedIndex];

        if (selectedOption) {
          sendProductData(selectedOption.getAttribute('data-attentiveURL'));
        }
      }
    });

    $('body').on('quickview:ready', function (e) {
      var element = $(e.target).find('.product-quickview.product-detail, .product-quickview.product-set');

      if (element) {
        var attentiveActionURL = window.Attentive_Urls ? window.Attentive_Urls.attentiveMobileTrack : '',
          productId = element.data('pid');

        sendProductData(attentiveActionURL, productId);
      }
    }); */

    $(window).on('load', function () {
      var element = document.querySelector('[data-attentive-mobile]');
      var productEventEnabled = window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_PRODUCT_EVENT_ENABLED;

      if (!productEventEnabled) {
        console.log('Product View Event Disabled');
        return;
      }

      if (element) {
        var productId = element.getAttribute('data-pid');

        var getDataResults = attentiveBase.getViewData(attentiveBase.getAttentiveActionURL(), {
          productId: productId,
          viewType: 'PRODUCT'
        });

        attentiveBase.tagProductView(getDataResults);
      }
    });

    $(document).on('click', '[data-attr="color"] button', function (e) {
      e.preventDefault();

      var productEventEnabled = window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_PRODUCT_EVENT_ENABLED;

      if (!productEventEnabled) {
        console.log('Product View Event Disabled');
        return;
      }

      var getDataResults = attentiveBase.getViewData($(this).attr('data-attentiveGetViewDataURL'), {
        viewType: 'PRODUCT'
      });

      attentiveBase.tagProductView(getDataResults);
    });

    $(document).on('change', 'select[class*="select-"]', function (e) {
      e.preventDefault();

      var productEventEnabled = window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_PRODUCT_EVENT_ENABLED;

      if (!productEventEnabled) {
        console.log('Product View Event Disabled');
        return;
      }

      var targetOptions = e.currentTarget.options;

      if (targetOptions) {
        var selectedOption = targetOptions[targetOptions.selectedIndex];
        var getDataResults = attentiveBase.getViewData(selectedOption.getAttribute('data-attentiveGetViewDataURL'), {
          viewType: 'PRODUCT'
        });

        attentiveBase.tagProductView(getDataResults);
      }
    });

    $('body').on('quickview:ready', function (e) {
      var productEventEnabled = window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_PRODUCT_EVENT_ENABLED;

      if (!productEventEnabled) {
        console.log('Product View Event Disabled');
        return;
      }

      var element = $(e.target).find('.product-quickview.product-detail, .product-quickview.product-set');

      if (element) {
        var productId = element.data('pid');
        var getDataResults = attentiveBase.getViewData(attentiveBase.getAttentiveActionURL(), {
          productId: productId,
          viewType: 'PRODUCT'
        });

        attentiveBase.tagProductView(getDataResults);
      }
    });
  }
};
