/* eslint-disable linebreak-style */
/* eslint-disable max-len */
/* eslint-disable one-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
'use strict';

/**
 * Attentive mobile common utilities
 */


function getViewData(attentiveActionURL, requestData) {
    return $.ajax({
        dataType: 'json',
        type: 'GET',
        data: requestData,
        url: attentiveActionURL
    });
}

function getAttentiveActionURL() {
    return window.Attentive_Urls ? window.Attentive_Urls.attentiveMobileGetViewData : '';
}

function tagProductView(getDataResults) {
    if (getDataResults) {
        getDataResults.done(function(data) {
            if (data && data.success) {
                try {
                    var currentInterval = window.setInterval(function(){
                    if (window.attentive && window.attentive.analytics) {
                        window.attentive.analytics.productView(data.viewData);
                        window.clearInterval(currentInterval);

                        console.log('Attentive Mobile Product View posted:');
                    }
                    }, 300);
                } catch (e) {
                    if (!typeof(currentInterval) === 'undefined') {
                        window.clearInterval(currentInterval);
                    }

                    console.error('Attentive Mobile product view posted:'.concat(e.message));
                }
            }
        }).fail(function(x) {
            // Tell the user something bad happened
        });
    }
}

function tagCartView(getDataResults) {
    if (getDataResults) {
        getDataResults.done(function(data) {
            if (data && data.success) {
                try {
                    var currentInterval = window.setInterval(function(){
                        if (window.attentive && window.attentive.analytics) {
                            window.attentive.analytics.addToCart(data.viewData);
                            window.clearInterval(currentInterval);
                            console.log('Attentive Mobile add to cart view posted');
                        }
                    }, 300);
                } catch (e) {
                    if (!typeof(currentInterval) === 'undefined') {
                        window.clearInterval(currentInterval);
                    }

                    console.error('Attentive Mobile Product View:'.concat(e.message));
                }
            }
        }).fail(function(x) {
            // Tell the user something bad happened
        });
    }
}

function tagPurchaseView(getDataResults) {
    if (getDataResults) {
        getDataResults.done(function(data) {
            if (data && data.success) {
                try {
                    var currentInterval = window.setInterval(function(){
                        if (window.attentive && window.attentive.analytics) {
                            window.attentive.analytics.purchase(data.viewData);
                            window.clearInterval(currentInterval);
                            console.log('Attentive Mobile purchase view posted');
                        }
                    }, 300);
                } catch (e) {
                    if (!typeof(currentInterval) === 'undefined') {
                        window.clearInterval(currentInterval);
                    }

                    console.error('Attentive Mobile Purchase View:'.concat(e.message));
                }
            }
        }).fail(function(x) {
            // Tell the user something bad happened
        });
    }
}

module.exports = {
    getViewData: getViewData,
    getAttentiveActionURL: getAttentiveActionURL,
    tagProductView: tagProductView,
    tagCartView: tagCartView,
    tagPurchaseView: tagPurchaseView
};
