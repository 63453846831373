/* eslint-disable linebreak-style */
/* eslint-disable global-require */
/* eslint-disable max-len */
'use strict';
/* global $ */
var processInclude = require('base/util');

// Intialize client side JS only if attentive mobile in enabled
if (window.Attentive_SitePreferences && window.Attentive_SitePreferences.ATTENTIVE_MOBILE_IS_ENABLED) {
  $(document).ready(function () {
    processInclude(require('./product/product.js'));
    processInclude(require('./cart/attentiveCart.js'));
  });
}

